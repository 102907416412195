import React from 'react';
import styled from "styled-components";

const Div = styled.div`
  width: 100%;
  height: var(--main-content-height);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cdcdcd;
  font-weight: bold;
  font-size: 2rem;
`

const Dashboard = props => {
  return <Div>
    Stadjatten
  </Div>
}

export default Dashboard;
