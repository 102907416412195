import styled, {css} from "styled-components";

const StyledGraph = styled.div`
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;

  ${props => props.gridArea ? css`grid-area: ${props.gridArea};` : css`grid-area: graph;`}
  ${props => props.smallHeader && css`border: 1px solid #412b2a;`}
  .graph-area {
    ${props => {
      if (props.smallHeader) {
        return css`
          height: calc(100% - 20px);

          text {
            font-size: 12px;
          }
        `
      } else {
        return css`
          min-height: var(--graph-height);
          height: var(--graph-height);

          text {
            font-size: 16px;
          }
        `
      }
    }
    }
    background: #21282c;
  }

  #info {
    background: #1a2530;
    color: #c1c1c1;
    width: 100%;
    height: var(--header-height);
    display: flex;
    align-items: center;
    padding: 0 20px;

    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    #title {
      padding: 0;
      margin: 0;
      font-weight: bold;
      display: flex;
      align-items: center
    }

    .graph-last-point-info {
      padding: 0;
      margin: 0;
      font-family: Roboto sans-serif;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > b {
        color: #3f97d4;
        font-family: Roboto, sans-serif;
        font-size: 1.2rem;
      }
    }

    div.form-check {
      padding-top: 15px;
    }
  }

  #small-info {
    background: #1a2530;
    color: #c1c1c1;
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;

    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    #title {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center
    }

    .graph-last-point-info {
      padding: 0;
      margin: 0;
      font-family: Roboto sans-serif;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > b {
        color: #3f97d4;
        font-family: Roboto, sans-serif;
      }
    }

    div.form-check {
      padding-top: 15px;
    }

    .dropdown-menu {
      width: 100px;
    }

    p.dropdown-item {
      font-size: 12px;
      margin: 0;
    }
  }


  .yAxis text, .xAxis text {
    stroke-width: 0.02;
    stroke: none;
    fill: #86682e;
  }

  .yAxis line, .xAxis line {
    stroke: #64d4ff;
    opacity: 0.1;
  }

  .yAxis path, .xAxis path {
    stroke: #39e1e5;
  }
  
`

export default StyledGraph;
