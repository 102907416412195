import graphTypes from "../../enums/graphTypes.enum";
import AverageClosingTimeSideCard from "../../components/graphs/side-cards/AverageClosingTimeSideCard";
import AverageRevenueSideCard from "../../components/graphs/side-cards/AverageRevenueSideCard";
import HitRateSideCard from "../../components/graphs/side-cards/HitRateSideCard";

const getSidebar = (graphId) => {
  let sideBarComponent;

  switch (graphId) {
    case graphTypes.loxysoftAverageClosingTime:
      sideBarComponent = <AverageClosingTimeSideCard dealSource={'loxysoft'}/>
      break
    case graphTypes.loxysoftAverageRevenue:
      sideBarComponent = <AverageRevenueSideCard dealSource={'loxysoft'}/>
      break
    case graphTypes.loxysoftHitrate:
      sideBarComponent = <HitRateSideCard dealSource={'loxysoft'}/>
      break

    case graphTypes.othersAverageClosingTime:
      sideBarComponent = <AverageClosingTimeSideCard dealSource={'others'}/>
      break
    case graphTypes.othersAverageRevenue:
      sideBarComponent = <AverageRevenueSideCard dealSource={'others'}/>
      break
    case graphTypes.othersHitrate:
      sideBarComponent = <HitRateSideCard dealSource={'others'}/>
      break

    default:
      sideBarComponent = null;
      break
  }
  return sideBarComponent;
}


export {getSidebar}
