import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import {ThemeProvider} from "styled-components";
import theme from './styles/theme';
import {createContext, useEffect, useState} from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import Graph from './components/graphs/Graph'
import Downloads from "./components/Downloads";
import axios from 'axios';
import io from "socket.io-client";
import notificationAudioFile from './audio/notification.mp3';
import MultipleGraphs from "./components/graphs/MultipleGraphs";
import TestSmallGraph from "./components/graphs/TestSmallGraph";

const serverURL = process.env.REACT_APP_SERVER_URL;
// const serverURL = 'https://api.stadjatten.se';
// const serverURL = 'http://localhost:5000';

console.log({serverURL})
axios.defaults.baseURL = serverURL;

const socket = io(serverURL);
const sideCardSocket = io(`${serverURL}/side-card`)

const notificationSound = new Audio(notificationAudioFile);

export const AuthContext = createContext();
export const GraphContext = createContext();
const playNotificationSound = async () => {
  await notificationSound.play();
}


const App = () => {
  const [auth, setAuth] = useState({isAuthenticated: true});
  const [userVerified, setUserVerified] = useState(false);
  const [notify, setNotify] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [sideCards, setSideCards] = useState([]);

  useEffect(() => {
    socket.on('points', (points) => {
      const indexedPoints = points.map((point, index) => {
        point.index = index;
        return point
      })
      setGraphData(indexedPoints);
      console.log('initial points received...')
    });

    socket.on('new-point', (point) => {
      point.index = graphData.length;
      setGraphData([...graphData, point]);
      console.log('new point received...')
      if (notify) playNotificationSound().then();
    })

    sideCardSocket.on('cards', (cards) => {
      setSideCards(cards)
    })

    sideCardSocket.on('new-card', (newCard) => {
      setSideCards([...sideCards, newCard])
    })
  })

  return <div className="App">
    <AuthContext.Provider value={auth}>
      <GraphContext.Provider value={{graphData, notify, setNotify, sideCards}}>
        <ThemeProvider theme={theme}>
          <Router>
            <Navbar/>
            <Switch>
              <main>
                <Route exact path='/login' component={Login}/>
                <ProtectedRoute path='/' component={Dashboard}/>
                <ProtectedRoute path='/dashboard' component={Dashboard}/>
                <ProtectedRoute path='/downloads' component={Downloads}/>
                <ProtectedRoute path='/graphs/:graphId' component={Graph}/>
                <ProtectedRoute path='/multiple-graphs/:graphId' component={MultipleGraphs}/>
                <ProtectedRoute path='/test' component={TestSmallGraph}/>
              </main>
            </Switch>
          </Router>
        </ThemeProvider>
      </GraphContext.Provider>
    </AuthContext.Provider>
  </div>;
}

export default App;
