import React, {useContext} from 'react';
import {AuthContext} from "../App";
import {Redirect, Route} from 'react-router-dom';

const ProtectedRoute = ({path, component}) => {
  const {isAuthenticated} = useContext(AuthContext);
  if (isAuthenticated)
    return <Route exact path={path} component={component}/>;
  else
    return <Redirect to='/login'/>
}

export default ProtectedRoute;
