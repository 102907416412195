import React from 'react';
import styled from "styled-components";
import RenderGraph from "./RenderGraph";
import GraphTypes from "../../enums/graphTypes.enum";
import {getGraphUtils} from "../../utils/graphs/graph.utils";

const SmallGraph = styled.div`
  height: 50%;
  width: 50%;
  background: orange;
`


const TestSmallGraph = (props) => {
  return <SmallGraph>
    <RenderGraph graphUtils={getGraphUtils(GraphTypes.othersPredictionOfRevenue)} smallHeader={true}/>
  </SmallGraph>
}

export default TestSmallGraph;
