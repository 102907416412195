const graphTypes = {
  loxysoftAverageClosingTime: 'loxysoft-average-closing-time',
  loxysoftAverageRevenue: 'loxysoft-average-revenue',
  loxysoftHitrate: 'loxysoft-hitrate',
  loxysoftPredictionOfAgreements: 'loxysoft-prediction-of-agreements',
  loxysoftPredictionOfRevenue: 'loxysoft-prediction-of-revenue',
  othersAverageClosingTime: 'others-average-closing-time',
  othersAverageRevenue: 'others-average-revenue',
  othersHitrate: 'others-hitrate',
  othersPredictionOfAgreements: 'others-prediction-of-agreements',
  othersPredictionOfRevenue: 'others-prediction-of-revenue',
}

export default graphTypes;
