/*
* Others - Prediction of Revenue
*/

const parseDataPoint = (point) => {
  return point.others.agreements
    / (point.others.agreements + point.others.rejections)
    * point.others.tenders
    * point.others.averageRevenue;
}

const displayDetails = (lastPoint) => {
  const title = 'Prediction of Revenue - others' // this line will change
  const lastData = Math.round(parseDataPoint(lastPoint))  // this line might change
  const data = `${lastData}kr for ${Math.round(lastData / lastPoint.others.averageRevenue)} deals` // this line will change

  const dateMatch = /\w+ (\w+) (\d{1,2}) \d+ (\d+:\d+)/.exec(String(new Date(lastPoint.time)))
  const lastUpdateTime = `${dateMatch[2]} ${dateMatch[1]}, ${dateMatch[3]}`
  return {title, data, lastUpdateTime}
}

export default {parseDataPoint, displayDetails};
