import React from 'react';
import Logo from '../images/logo.png';
import styled from "styled-components";
import {Link} from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min'
import graphTypes from "../enums/graphTypes.enum";
import l2r2 from '../svgs/l2r2.svg';
import l1r1 from '../svgs/l1r1.svg';
import l2r1 from '../svgs/l2r1.svg';
import l1r2 from '../svgs/l1r2.svg';

const Nav = styled.nav`
  .dropdown-menu {
    background: #2C3E50;

    a.dark {
      color: #ebebeb;

      &:hover {
        background: #1d2f3a;
      }
    }

    .dropdown-divider {
      background: #adadad;
    }
  }

  #logo {
    width: 150px;
  }
`

const FreeFromGraphs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  img {
    width: 50px;
    margin: 0 10px;
    transition: all 200ms;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
`


const Navbar = () => {
  return <Nav className="navbar navbar-expand-lg navbar-dark bg-primary">
    <div className="container">
      <Link className="navbar-brand" to="/">
        <img src={Logo} alt="logo" id="logo"/>
      </Link>

      <div className="navbar">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
               aria-haspopup="true" aria-expanded="false">Graphs</a>
            <div className="dropdown-menu">
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.loxysoftAverageClosingTime}`}>Loxysoft
                Average
                closing time</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.loxysoftAverageRevenue}`}>Loxysoft Average
                Revenue</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.loxysoftHitrate}`}>Loxysoft Hit Rate</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.loxysoftPredictionOfAgreements}`}>Loxysoft
                Prediction of Agreements</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.loxysoftPredictionOfRevenue}`}>Loxysoft
                Prediction of Revenue </Link>
              <div className="dropdown-divider"/>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.othersAverageClosingTime}`}>Others Average
                closing time</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.othersAverageRevenue}`}>Others Average
                Revenue</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.othersHitrate}`}>Others Hit Rate</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.othersPredictionOfAgreements}`}>Others
                Prediction of Agreements</Link>
              <Link className="dropdown-item dark" to={`/graphs/${graphTypes.othersPredictionOfRevenue}`}>Others
                Prediction
                of Revenue </Link>
              <div className="dropdown-divider"/>
              <FreeFromGraphs>
                <Link to='/multiple-graphs/l1r1'>
                  <img src={l1r1} alt="left 1 right 1"/>
                </Link>
                <Link to='/multiple-graphs/l2r2'>
                  <img src={l2r2} alt="left 2 right 2"/>
                </Link>
                <Link to='/multiple-graphs/l1r2'>
                  <img src={l1r2} alt="left 1 right 2"/>
                </Link>
                <Link to='/multiple-graphs/l2r1'>
                  <img src={l2r1} alt="left 2 right 1"/>
                </Link>

              </FreeFromGraphs>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/downloads">Downloads</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">Dashboard</Link>
          </li>
        </ul>
      </div>
    </div>
  </Nav>
}

export default Navbar;
