/*
* Loxysoft - Hit Rate
*/

const parseDataPoint = (point) => {
  return point.loxysoft.agreements / (point.loxysoft.agreements + point.loxysoft.rejections) * 100
}

const displayDetails = (lastPoint) => {
  const title = 'Hit Rate - Loxysoft' // this line will change
  const lastData = Math.round(parseDataPoint(lastPoint) * 100) / 100  // this line might change
  const data = `${lastData}%` // this line will change

  const dateMatch = /\w+ (\w+) (\d{1,2}) \d+ (\d+:\d+)/.exec(String(new Date(lastPoint.time)))
  const lastUpdateTime = `${dateMatch[2]} ${dateMatch[1]}, ${dateMatch[3]}`

  return {title, data, lastUpdateTime}
}

export default {parseDataPoint, displayDetails};
