import {useParams} from 'react-router-dom';
import styled, {css} from "styled-components";
import {getGraphUtils} from "../../utils/graphs/graph.utils";
import graphTypes from "../../enums/graphTypes.enum";
import {useEffect, useState} from "react";
import RenderGraph from "./RenderGraph";
import SelectGraph from "./SelectGraph";

const MultiGraphHolder = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 100%;

  ${props => {
    if (props.type === 'l2r2') { // left 2 right 2
      return css`
        grid-template-areas:
                "one two"
                "three four"
      `
    } else if (props.type === 'l1r1') { // left 1 right 1
      return css`grid-template-areas:
                "one two"
                "one two"
      `
    } else if (props.type === 'l1r2') { // left 1 right 2
      return css`
        grid-template-areas:
                "one two"
                "one three"
      `
    } else if (props.type === 'l2r1') { // left 2 right 1
      return css`
        grid-template-areas:
                "one three"
                "two three"
      `
    }
  }}
`


const PlaceHolderGraph = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.26);
  background: #273240;
  grid-area: ${props => props.gridArea};
  display: flex;
  justify-content: center;
  align-items: center;


  button {
    font-size: 1rem;
    cursor: pointer;
    background: #2C3E50;
    border: 2px solid #25709e;
    color: #c8c8c8;
    padding: 10px 20px;
    border-radius: 25px;
    outline: none;
    transition: all 200ms;

    &:hover {
      transform: scale(1.04);
    }
  }
`


const SingleGraph = ({gridArea}) => {
  const [selectedGraph, setSelectedGraph] = useState();
  const [showSelectGraphModal, setShowSelectGraphModal] = useState(false);

  const selectGraph = () => {
    setShowSelectGraphModal(true);
  }

  useEffect(() => {
    setSelectedGraph(null);
  }, [])

  if (!selectedGraph)
    return <PlaceHolderGraph gridArea={gridArea}>
      <SelectGraph setSelectedGraph={setSelectedGraph}
                   setShowSelectGraphModal={setShowSelectGraphModal}
                   showSelectGraphModal={showSelectGraphModal}/>
      <button onClick={selectGraph}>Add Graph</button>
    </PlaceHolderGraph>
  else {
    const graphUtils = getGraphUtils(selectedGraph);
    return <RenderGraph smallHeader={true} graphUtils={graphUtils} gridArea={gridArea}/>
  }
}


const MultipleGraphs = () => {
  const {graphId} = useParams();

  if (graphId === 'l2r2') {
    return <MultiGraphHolder type={graphId}>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='one'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='two'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='three'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='four'/>
    </MultiGraphHolder>
  } else if (graphId === 'l1r1') {
    return <MultiGraphHolder type={graphId}>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='one'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='two'/>
    </MultiGraphHolder>
  } else if (graphId === 'l1r2') {
    return <MultiGraphHolder type={graphId}>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='one'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='two'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='three'/>
    </MultiGraphHolder>
  } else if (graphId === 'l2r1') {
    return <MultiGraphHolder type={graphId}>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='one'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='two'/>
      <SingleGraph graphUtils={getGraphUtils(graphTypes.loxysoftAverageClosingTime)} gridArea='three'/>
    </MultiGraphHolder>
  }
}

export default MultipleGraphs;
