import React, {useContext, useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import StyledGraph from "./graph.styles";
import {GraphContext} from "../../App";
import Spinner from "../Spinner";
import {format} from "date-fns";

const RenderGraph = ({graphUtils, smallHeader, gridArea}) => {
  const [selectedTimeSpanText, setSelectedTimeSpanText] = useState('All');
  const d3Ref = useRef();
  const toolTipRef = useRef();
  const graphContainerRef = useRef();
  const [title, setTitle] = useState(); // title which is shown on the graph header
  const [displayText, setDisplayText] = useState() // data which is shown on the graph header
  const [lastDataTime, setLastDataTime] = useState();
  const [width, setWidth] = useState(); // width of graph
  const [height, setHeight] = useState(); // height of graph
  const {graphData, notify, setNotify} = useContext(GraphContext);
  const [domRect, setDomRect] = useState();

  const margin = {top: 10, right: 10, bottom: 40, left: 70};
  let data, selectedData, timeSpan = 'all';

  const parseData = () => {
    const parsed = [];
    let lastValue;
    let index = 0;
    for (let i = 0; i < selectedData.length; i++) {
      const point = selectedData[i];
      const currentValue = graphUtils.parseDataPoint(point);
      if (currentValue !== lastValue || i === selectedData.length - 1) {
        parsed.push({
          value: currentValue,
          date: d3.isoParse(point.time),
          index: index
        });
        index++;
      }
      lastValue = currentValue;
    }
    return parsed;
  }

  const changeTimeSpan = (duration) => {
    timeSpan = duration;
    const currentTime = new Date();
    selectedData = [];

    switch (duration) {
      case 'all':
        selectedData = graphData;
        setSelectedTimeSpanText('All');
        break
      case 'day':
        graphData.forEach(dataPoint => {
          if ((currentTime - new Date(dataPoint.time)) / 1000 / 3600 <= 24)
            selectedData.push(dataPoint)
        })
        setSelectedTimeSpanText('Today');
        break
      case 'week':
        graphData.forEach(dataPoint => {
          if ((currentTime - new Date(dataPoint.time)) / 1000 / 3600 / 24 <= 7)
            selectedData.push(dataPoint)
        })
        setSelectedTimeSpanText('This Week');
        break
      case 'month':
        graphData.forEach(dataPoint => {
          // console.log((currentTime - new Date(dataPoint.time)) / 1000 / 3600 / 24)
          if ((currentTime - new Date(dataPoint.time)) / 1000 / 3600 / 24 <= 30)
            selectedData.push(dataPoint)
        })
        setSelectedTimeSpanText('This Month');
        break
      case 'year':
        graphData.forEach(dataPoint => {
          if ((currentTime - new Date(dataPoint.time)) / 1000 / 3600 / 24 <= 365)
            selectedData.push(dataPoint)
        })
        setSelectedTimeSpanText('This Year');
        break
      default:
        break
    }

    renderGraph();
  }

  const renderGraph = () => {
    // remove old data if exists
    d3Ref.current.innerHTML = '';

    data = parseData();

    // set the info at header
    const {
      title: _title,
      data: _displayData,
      lastUpdateTime
    } = graphUtils.displayDetails(graphData[graphData.length - 1]);

    setTitle(_title);
    setDisplayText(_displayData);
    setLastDataTime(lastUpdateTime);


    // append the svg object to the body of the page
    const svg = d3.select(d3Ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);


    // Add X axis --> it is a date format
    const xTime = d3.scaleTime()
      .domain(d3.extent(data, d => d.time))
      .range([0, width])

    const x = d3.scaleLinear()
      .domain(d3.extent(data, d => d.index))
      .range([0, width])


    const xAxis = svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .attr("class", "xAxis")
      .call(d3.axisBottom(x).ticks(5));

    // Add Y axis
    const yDomainMin = d3.min(data, d => d.value)
    const yDomainMax = d3.max(data, d => +d.value)
    const yDomainSpan = yDomainMax - yDomainMin

    const y = d3.scaleLinear()
      .domain([yDomainSpan ? yDomainMin - yDomainSpan * 0.1 : yDomainMin * 0.7, yDomainSpan ? yDomainMax + yDomainSpan * 0.1 : yDomainMax * 1.3])
      .range([height, 0]);

    const yAxis = svg
      .append('g')
      .attr("class", "yAxis")
      .call(d3.axisLeft(y));


    // Create the line variable: where both the line and the brush take place
    const line = svg.append('g')
      .attr('clip-path', 'url(#clip)')

    // Add the line
    const path = line.append('path')
      .datum(data)
      .attr('class', 'line')  // I add the class line to be able to modify this line later on.
      .attr('fill', 'none')
      .attr('stroke', 'rgb(90,144,87)')
      .attr('stroke-width', 3)
      .attr('d', d3.line()
        .curve(d3.curveMonotoneX)
        .x(d => x(d.index))
        .y(d => y(d.value))
      );

    line
      .on("mouseover", function () {
        d3.selectAll('circle').attr('opacity', 1)
      })
      .on("mouseout", function () {
        d3.selectAll('circle').attr('opacity', 0)
      })

    addGrid()

    /*--------------------  function definitions  ---------------------------*/
    // This allows to find the closest X index of the mouse:
    const bisect = d3.bisector(d => d.index).left;

    svg
      .append('rect')
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseout);

    // Create the circle that travels along the curve of chart
    const focus = svg
      .append('g')
      .append('circle')
      .style("fill", "#e7a63e")
      .attr("stroke", "white")
      .attr('r', 5)
      .style("opacity", 0)

    const toolTip = svg
      .append('g')
      .append('text')
      .style('fill', 'red')
      .attr('class', 'graph-tooltip')
      .style("opacity", 0)
      .attr("text-anchor", "left")
      .attr("alignment-baseline", "middle")

    // create a tooltip
    const htmlTooltip = d3.select(toolTipRef.current)
      .append("div")
      .attr('class', 'tooltip-div')
      .style("position", "absolute")
      .style('font-size', '14px')
      .style('z-index', '1000000')
      .style('font-family', 'Roboto,sans-serif')
      .style("visibility", "hidden")
      .style("min-width", "130px")
      .style("background", "#f3f3f3")
      .style('box-shadow', '-5px 5px 15px 5px rgba(0,0,0,0.3)')
      .style('color', '#2C3E50')
      .style("border-radius", "5px")
      .style("padding", "8px")


    // What happens when the mouse move -> show the annotations at the right positions.
    function mouseover() {
      focus.style("opacity", 1)
      toolTip.style("opacity", 1)
      htmlTooltip.style('visibility', "visible")
    }

    function mousemove() {
      // remove old tooltips
      const existingTooltips = document.querySelectorAll('.tooltip-div')

      existingTooltips.forEach((div, i) => {
        if (i === existingTooltips.length - 1) return
        div.style.display = 'none'
      })


      // recover coordinate we need
      const x0 = x.invert(d3.mouse(this)[0]);
      const i = bisect(data, x0, 1);
      selectedData = data[i]
      focus
        .attr("cx", x(selectedData.index))
        .attr("cy", y(selectedData.value))

      htmlTooltip
        .html(() => {
          const dt = new Date(selectedData.date)
          const d = format(dt, 'd MMM')
          const t = format(dt, 'p')

          return `
            Time: ${t}
            <br>
            Date: ${d}
            <br>
            Value: ${Math.round(selectedData.value)}
          `
        })
        .style('top', d3.event.pageY - domRect.top + 'px')
        .style('left', d3.event.pageX - domRect.left + 'px')
    }

    function mouseout() {
      focus.style("opacity", 0)
      htmlTooltip.style("visibility", "hidden")
    }

    function addGrid() {
      setTimeout(() => {
        try {
          d3Ref.current.querySelector('.yAxis').querySelectorAll('line').forEach(line => {
            line.setAttribute('x2', width)
          })
          d3Ref.current.querySelector('.xAxis').querySelectorAll('line').forEach(line => {
            line.setAttribute('y2', -height)
          })
        } catch (err) {
          console.log(err.message);
        }
      }, 200)
    }
  }

  useEffect(() => {
    // for next renders
    setWidth(d3Ref.current.clientWidth - margin.left - margin.right);
    setHeight(d3Ref.current.clientHeight - margin.bottom - margin.top);
    // console.log('height:', d3Ref.current.clientHeight)
    if (graphData.length > 0) {
      // console.log({currentRef: d3Ref.current});
      changeTimeSpan(timeSpan);
    }
  }, [graphData, width, height, timeSpan, graphUtils])

  useEffect(() => {

    window.addEventListener('resize', () => {
      setWidth(d3Ref.current.clientWidth - margin.left - margin.right);
      setHeight(d3Ref.current.clientHeight - margin.bottom - margin.top);
    });
  })

  useEffect(() => {
    const {top, left} = graphContainerRef.current.getBoundingClientRect();
    setDomRect({top, left})
  }, [])

  return <StyledGraph smallHeader={smallHeader} gridArea={gridArea} ref={graphContainerRef}>
    <div ref={toolTipRef}/>
    {smallHeader
      ? <div id={"small-info"}>
        <div>
          <p id="title">{title}</p>
          <div className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="changeTimeSpan" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
              {selectedTimeSpanText}
            </a>
            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="changeTimeSpan">
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('all')
                }}>All</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('day')
                }}>Today</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('week')
                }}>This Week</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('month')
                }}>This Month</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('year')
                }}>This Year</p>
              </li>
            </ul>
          </div>
          <p className="graph-last-point-info">
            <b>{displayText}</b>&nbsp;{displayText && `at`}&nbsp;{lastDataTime}
          </p>
        </div>
      </div>
      : <div id="info">
        <div>
          <p id="title" style={{width: 'calc(50% - 200px)'}}>{title}</p>
          <div className="form-check form-switch" style={{width: '160px', paddingTop: '10px'}}>
            <label className="form-check-label" htmlFor="notification-toggle">Notification</label>
            <input className="form-check-input" type="checkbox" id="notification-toggle" checked={notify}
                   onChange={() => setNotify(!notify)}/>
          </div>
          <div className="nav-item dropdown" style={{width: '200px', marginLeft: '40px'}}>
            <a className="nav-link dropdown-toggle" href="#" id="changeTimeSpan" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
              {selectedTimeSpanText}
            </a>
            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="changeTimeSpan">
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('all')
                }}>All</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('day')
                }}>Today</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('week')
                }}>This Week</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('month')
                }}>This Month</p>
              </li>
              <li>
                <p className="dropdown-item" onClick={() => {
                  changeTimeSpan('year')
                }}>This Year</p>
              </li>
            </ul>
          </div>
          <p className="graph-last-point-info" style={{width: 'calc(50% - 200px)', textAlign: 'right'}}>
            <b>{displayText}</b>&nbsp;{displayText && `at`}&nbsp;{lastDataTime}
          </p>
        </div>
      </div>
    }
    <div className="graph-area" ref={d3Ref}>
      <Spinner/>
    </div>
  </StyledGraph>
}

export default RenderGraph;
