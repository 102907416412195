import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {GraphContext} from "../../../App";
import {format} from 'date-fns';

const SideBar = styled.div`
  height: var(--main-content-height);
  width: 100%;
  grid-area: sidebar;
  background: #1A2530;
`

const Card = styled.div`
  border-radius: 8px;
  color: #b0b0b0;
  font-size: 15px;
  width: calc(100% - 10px);
  border: 2px solid ${(props) => props.backgroundColor};
  margin: 10px 5px;
  padding: 10px 20px;
  font-family: Roboto, sans-serif;
  box-shadow: -3px 3px 10px -5px rgba(0, 0, 0, 0.6);

  td {
    padding-right: 3px;
  }
;
`

const SidebarCard = ({cardData}) => {
  const backgroundColor = 'rgba(70,130,43,0.7)';

  const getTimeToClose = (data) => {
    try {
      const daysTaken = Math.round((new Date(data.closeDate) - new Date(data.quoteSent)) / (1000 * 3600 * 24))
      return `${daysTaken} day${daysTaken === 1 ? '' : 's'}`
    } catch (err) {
      return '---'
    }
  }

  const getCloseDate = (data) => {
    try {
      return format(new Date(data.closeDate), 'd MMM')
    } catch (err) {
      console.log(err)
      return '---'
    }
  }

  return <Card backgroundColor={backgroundColor}>
    <table>
      <tbody>
      <tr>
        <td>Deal ID</td>
        <td>: {cardData.dealId}</td>
      </tr>
      <tr>
        <td>Time Taken</td>
        <td>: {getTimeToClose(cardData)}</td>
      </tr>
      <tr>
        <td>Close Date</td>
        <td>: {getCloseDate(cardData)}</td>
      </tr>
      </tbody>
    </table>
  </Card>
}

const GraphSidebar = ({dealSource}) => {
  if (!dealSource) dealSource = 'others'

  const [cards, setCards] = useState([]);

  const {sideCards} = useContext(GraphContext);

  useEffect(() => {
    const showCards = sideCards.filter(card => {
      const ds = card.dealSource === 'loxysoft' ? 'loxysoft' : 'others'
      return card.dealStatus === 'agreement' && ds === dealSource
    })
    setCards(showCards);
  }, [sideCards, dealSource])

  if (cards.length > 0) {
    return <SideBar>
      {cards.map(card => <SidebarCard cardData={card}/>)}
    </SideBar>
  } else {
    return <SideBar>
      <p style={{color: '#cdcdcd', padding: '10px'}}>No data yet...</p>
    </SideBar>
  }
}

export default GraphSidebar;
