import React from 'react';
import styled from "styled-components";

const Div = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--main-content-height);
  padding: 20px;


  & a {
    display: block;
    text-decoration: none;
    background: var(--bs-primary);
    padding: 30px;
    margin: 5px;
    border-radius: 6px;
    color: #fafafa;
    font-family: sans-serif;
    text-align: center;
    transition: all 200ms;
  }

  a:hover {
    transform: scale(1.01);
  }

  #cache-info {
    display: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    justify-content: center;
    align-items: center;
  }

  #cache-info div {
    background: white;
    border-radius: 8px;
    padding: 15px;
  }
`


const Downloads = props => {
  const files = ['Stockholms län', 'Göteborgs kommun'];

  return <>
    <Div id="links" className="shadow">
      <div id="dynamic-links">
        {files.map(file => (
          <a href={`${process.env.REACT_APP_SERVER_URL}/docs/${file}.xlsx`} download
             className="download-link">{`Download ${file}`}.xlsx</a>
        ))}
      </div>
    </Div>
  </>
}

export default Downloads;
