/*
* Loxysoft - Average revenue
*/

const parseDataPoint = (point) => {
  return point.loxysoft.averageRevenue
}

const displayDetails = (lastPoint) => {
  const title = 'Average Revenue - Loxysoft' // this line will change
  const lastData = parseDataPoint(lastPoint) // this line might change
  const data = `${lastData}kr` //unit will change

  const dateMatch = /\w+ (\w+) (\d{1,2}) \d+ (\d+:\d+)/.exec(String(new Date(lastPoint.time)))
  const lastUpdateTime = `${dateMatch[2]} ${dateMatch[1]}, ${dateMatch[3]}`

  return {title, data, lastUpdateTime};
}


export default {parseDataPoint, displayDetails};
