/*
* Loxysoft - Average closing time
*/

const parseDataPoint = (point) => {
  try {
    return point.loxysoft.averageClosingTime
  } catch (err) {
    console.log(err);
    return 0
  }
}

const displayDetails = (lastPoint) => {
  const title = 'Average Closing Time - Loxysoft' // this line will change
  const lastData = parseDataPoint(lastPoint) // this line might change
  const data = `${lastData} days` // this line will change

  const dateMatch = /\w+ (\w+) (\d{1,2}) \d+ (\d+:\d+)/.exec(String(new Date(lastPoint.time)))
  const lastUpdateTime = `${dateMatch[2]} ${dateMatch[1]}, ${dateMatch[3]}`

  return {title, data, lastUpdateTime}
}

export default {parseDataPoint, displayDetails};
