import RenderGraph from "./RenderGraph";
import {useParams} from 'react-router-dom';
import styled from "styled-components";
import {getGraphUtils} from "../../utils/graphs/graph.utils";
import {getSidebar} from "../../utils/graphs/side-bar.utils";
import {useEffect, useState} from "react";


const WithSidebar = styled.div`
  display: grid;
  grid-template-areas: "graph sidebar";
  grid-template-columns: auto 200px;
`

const NoSideBar = styled.div`
  display: block;
  width: 100%;
`

const Graph = () => {
  const {graphId} = useParams();
  let graphUtils = getGraphUtils(graphId);
  const sideBar = getSidebar(graphId)
  const [showSideBar, setShowSideBar] = useState(true);

  useEffect(() => {
    setShowSideBar(!!sideBar);
  }, [sideBar])

  if (showSideBar)
    return <WithSidebar>
      <RenderGraph graphUtils={graphUtils}/>
      {sideBar}
    </WithSidebar>
  else
    return <NoSideBar>
      <RenderGraph graphUtils={graphUtils}/>
    </NoSideBar>
}

export default Graph;
