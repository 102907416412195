import React from 'react';
import styled from "styled-components";

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`


const Spinner = props => {
  const spinnerColor = props.color ? props.color : '#848484'

  return <Div>
    <div className="spinner-border" style={{color: spinnerColor}} role="status"/>
  </Div>
}

export default Spinner;
