import graphTypes from "../../enums/graphTypes.enum";
import loxysoftAverageClosingTimeUtils from "./loxysoft/loxysoftAverageClosingTime.utils";
import loxysoftAverageRevenueUtils from "./loxysoft/loxysoftAverageRevenue.utils";
import loxysoftHitrateUtils from "./loxysoft/loxysoftHitrate.utils";
import loxysoftPredictionOfAgreementsUtils from "./loxysoft/loxysoftPredictionOfAgreements.utils";
import loxysoftPredictionOfRevenueUtils from "./loxysoft/loxysoftPredictionOfRevenue.utils";
import othersAverageClosingTimeUtils from "./others/othersAverageClosingTime.utils";
import othersAverageRevenueUtils from "./others/othersAverageRevenue.utils";
import othersHitrateUtils from "./others/othersHitrate.utils";
import othersPredictionOfAgreementsUtils from "./others/othersPredictionOfAgreements.utils";
import othersPredictionOfRevenueUtils from "./others/othersPredictionOfRevenue.utils";

const getGraphUtils = (graphId) => {
  let graphUtils;

  switch (graphId) {
    case graphTypes.loxysoftAverageClosingTime:
      graphUtils = loxysoftAverageClosingTimeUtils
      break
    case graphTypes.loxysoftAverageRevenue:
      graphUtils = loxysoftAverageRevenueUtils
      break
    case graphTypes.loxysoftHitrate:
      graphUtils = loxysoftHitrateUtils
      break
    case graphTypes.loxysoftPredictionOfAgreements:
      graphUtils = loxysoftPredictionOfAgreementsUtils;
      break
    case graphTypes.loxysoftPredictionOfRevenue:
      graphUtils = loxysoftPredictionOfRevenueUtils;
      break

    case graphTypes.othersAverageClosingTime:
      graphUtils = othersAverageClosingTimeUtils
      break
    case graphTypes.othersAverageRevenue:
      graphUtils = othersAverageRevenueUtils
      break
    case graphTypes.othersHitrate:
      graphUtils = othersHitrateUtils
      break
    case graphTypes.othersPredictionOfAgreements:
      graphUtils = othersPredictionOfAgreementsUtils;
      break
    case graphTypes.othersPredictionOfRevenue:
      graphUtils = othersPredictionOfRevenueUtils;
      break

    default:
      alert('default triggered')
      graphUtils = loxysoftAverageClosingTimeUtils
      break
  }
  return graphUtils;
}

export {getGraphUtils};
