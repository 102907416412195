/*
* Others - Prediction of Agreements
*/

const parseDataPoint = (point) => {
  // hit rate * number of tenders
  return point.others.agreements / (point.others.agreements + point.others.rejections) * point.others.tenders;
}

const displayDetails = (lastPoint) => {
  const title = 'Prediction of Agreements - Others' // this line will change
  const lastData = Math.round(parseDataPoint(lastPoint) )  // this line might change
  const data = `${lastData}/${lastPoint.others.tenders}` // this line will change

  const dateMatch = /\w+ (\w+) (\d{1,2}) \d+ (\d+:\d+)/.exec(String(new Date(lastPoint.time)))
  const lastUpdateTime = `${dateMatch[2]} ${dateMatch[1]}, ${dateMatch[3]}`
  return {title, data, lastUpdateTime}
}

export default {parseDataPoint, displayDetails};
