/*
* Loxysoft - Prediction of Revenue
*/

const parseDataPoint = (point) => {
  return point.loxysoft.agreements
    / (point.loxysoft.agreements + point.loxysoft.rejections)
    * point.loxysoft.tenders
    * point.loxysoft.averageRevenue;
}

const displayDetails = (lastPoint) => {
  const title = 'Prediction of Revenue - Loxysoft' // this line will change
  const lastData = Math.round(parseDataPoint(lastPoint))  // this line might change
  const data = `${lastData}kr for ${Math.round(lastData / lastPoint.loxysoft.averageRevenue)} deals` // this line will change

  const dateMatch = /\w+ (\w+) (\d{1,2}) \d+ (\d+:\d+)/.exec(String(new Date(lastPoint.time)))
  const lastUpdateTime = `${dateMatch[2]} ${dateMatch[1]}, ${dateMatch[3]}`
  return {title, data, lastUpdateTime}
}

export default {parseDataPoint, displayDetails};
