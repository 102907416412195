import React from 'react';
import styled, {css} from "styled-components";
import GraphTypes from '../../enums/graphTypes.enum';

const Div = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.hidden && css`display: none`}
  .selector {
    background: #2C3E50;
    padding: 0;
    border-radius: 10px;
    color: #c8c8c8;

    p {
      padding: 10px;
      margin: 0;

      &:hover {
        background: #1c2833;
        cursor: pointer;
      }
    }
  }
`


const SelectGraph = props => {
  const graphs = [];

  for (const [key, value] of Object.entries(GraphTypes)) {
    const _graphName = key.replace(/([A-Z])/g, " $1");
    const graphName = _graphName.charAt(0).toUpperCase() + _graphName.slice(1);
    graphs.push({graphName, graphId: value});
  }

  return <Div hidden={!props.showSelectGraphModal}>
    <div className='selector'>
      {graphs.map(graph => (
        <p onClick={() => props.setSelectedGraph(graph.graphId)}>{graph.graphName}</p>
      ))}
    </div>
  </Div>
}

export default SelectGraph;
